<template>
  <v-card class="pt-2 mx-auto" max-width="30rem" width="100%" elevation="0">
    <v-card-title>
      <h1 class="access-data__title mb-0">{{ $t('login.create_account') }}</h1>
    </v-card-title>

    <v-card-text>
      <v-row align="center" justify="center" class="pb-4">
        <v-col cols="12" class="pt-5">
          <v-text-field
            v-model="accountData.email"
            type="text"
            name="email"
            color="primary"
            autocomplete="username"
            :disabled="isLockedEmail"
            :label="$t('general.email')"
            :error="$v.accountData.email.$error || emailAlreadyUsed"
            outlined
            dense
            @blur="emailChanged = true"
          >
            <template v-slot:append>
              <s-help :message="$t('info.mail')"></s-help>
            </template>
          </v-text-field>

          <v-text-field
            v-model="accountData.password"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            autocomplete="new-password"
            :label="$t('password.title_and_rules')"
            :error="$v.accountData.password.$error"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            color="primary"
            outlined
            loading
            dense
            @click:append="showPassword = !showPassword"
            @blur="passwordChanged = true"
          >
            <template v-slot:progress>
              <v-progress-linear
                style="width: 98%; border-bottom-left-radius: 30px; border-bottom-right-radius: 30px"
                class="mx-1"
                :value="passwordScore.value"
                :color="passwordScore.color"
                absolute
                height="7"
              ></v-progress-linear>
            </template>
          </v-text-field>

          <div v-if="showPromocodeInput">
            <v-text-field
              v-model="value.promocode"
              type="text"
              name="promo-code"
              :label="$t('general.promocode')"
              append-outer-icon="mdi-close"
              class="mb-1"
              outlined
              dense
              hide-details
              @click:append-outer="clearPromoCode"
            ></v-text-field>
          </div>

          <v-row v-else align="center" justify="start" no-gutters>
            <v-col cols="auto" class="access-data__registration-note pb-0 mb-0">
              <a class="mr-1 mb-0" @click.prevent="showPromocodeInput = true">
                {{ $t('login.insert_promo_code') }}
              </a>
            </v-col>

            <v-col>
              <s-help :message="$t('login.insert_promo_code_description')"></s-help>
            </v-col>
          </v-row>
        </v-col>

        <v-expand-transition>
          <ErrorMessage v-if="errorMessage !== null">
            {{ errorMessage }}
          </ErrorMessage>
        </v-expand-transition>

        <v-col cols="12">
          <v-btn color="primary" block :loading="isNextStepLoading" :disabled="$v.accountData.$error" @click="nextStep">
            {{ $t('general.continue') }}
          </v-btn>
        </v-col>

        <v-col cols="12">
          <p class="access-data__registration-note pa-0 mb-0" v-html="agreeWithTosText"></p>
        </v-col>

        <v-col v-if="canUseExternalProvider" class="col-12 login-method__separator">
          <p>
            <span>{{ $t('general.or') }}</span>
          </p>
        </v-col>

        <v-col v-if="canUseExternalProvider" class="col-12">
          <GoogleSignInButton />
        </v-col>

        <!-- <v-col v-if="canUseExternalProvider" class="col-12 col-md-6">
          <MicrosoftSignInButton />
        </v-col> -->

        <v-col class="col-12 access-data__registration-note">
          {{ $t('login.already_have_account') }}

          <router-link :to="{ name: 'login' }">{{ $t('login.log_in') }}</router-link>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent } from 'vue'
import { environment } from '@/config/environment'
import { email, minLength, required } from 'vuelidate/lib/validators'
import { computePasswordScore } from '@/common/reusable/security'
import { createWorkspaceCheck, validatePromoCode } from '@access/services/accessService'
import { getErrorResponseMessage } from '@/common/reusable/errorResponse'
import ErrorMessage from '@/components/ErrorMessage'
import GoogleSignInButton from '@/app/modules/login/components/GoogleSignInButton.vue'
// import MicrosoftSignInButton from '@/app/modules/login/components/MicrosoftSignInButton.vue'

export default defineComponent({
  name: 'RegistrationAccessData',
  components: {
    // MicrosoftSignInButton,
    GoogleSignInButton,
    ErrorMessage,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      showPassword: false,
      showPromocodeInput: false,
      emailAlreadyUsed: false,
      emailChanged: false,
      emailLocked: false,
      passwordChanged: false,
      nextStepLoading: false,
      errorMessage: null,
    }
  },
  computed: {
    accountData: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', Object.assign(this.value, value))
      },
    },
    canUseExternalProvider() {
      return environment.checkFeatureFlagState('canUseExternalProvider')
    },
    passwordScore() {
      return computePasswordScore({ password: this.accountData.password })
    },
    isLockedEmail() {
      return this.emailLocked
    },
    isNextStepLoading() {
      return this.nextStepLoading
    },
    agreeWithTosText() {
      return this.$t('login.agree_with_tos_auto.app_links', {
        terms: this.$t('links.terms_and_conditions.registered'),
        privacy: this.$t('links.privacy_policy'),
      })
    },
  },
  validations() {
    const containsUppercase = (value) => /[A-Z]/.test(value);
    const containsSpecialChar = (value) => /[0-9!@#$%^&*()_+[\]{}|;:'",.<>?/]/.test(value);

    return {
      accountData: {
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(8),
          containsUppercase,
          containsSpecialChar
        },
      },
    }
  },
  watch: {
    emailWasChanged: {
      handler: function () {
        if (this.emailChanged) {
          this.$v.accountData.email.$touch()
          this.emailChanged = false
          this.emailAlreadyUsed = false
        }
      },
    },
    passwordWasChanged: {
      handler: function () {
        if (this.passwordChanged) {
          this.$v.accountData.password.$touch()
          this.passwordChanged = false
        }
      },
    },
  },
  created() {
    const prefilledEmail = this.$route?.query?.email
    const prefilledPromoCode = this.$route?.query?.code

    if (typeof prefilledEmail !== 'undefined') {
      this.accountData.email = prefilledEmail
      this.emailLocked = true
    }

    if (typeof prefilledPromoCode !== 'undefined') {
      this.accountData.promocode = prefilledPromoCode
      this.showPromocodeInput = true
    }
  },
  methods: {
    clearPromoCode() {
      this.accountData.promocode = ''
      this.showPromocodeInput = false
      this.errorMessage = null
    },
    async checkPromoCodeValidation() {
      await validatePromoCode({
        promoCode: this.accountData.promocode,
      })
        .then(() => {
          this.errorMessage = null
        })
        .catch((err) => {
          this.errorMessage = this.$t('payments.promocode.invalid')
          this.showPromocodeInput = true

          throw err?.response?.data
        })
    },
    async nextStep() {
      this.nextStepLoading = true
      this.errorMessage = null

      try {
        if ('' !== this.accountData.promocode) {
          await this.checkPromoCodeValidation()
        }

        const { createWorkspace, emailAlreadyUsed } = await createWorkspaceCheck({
          email: this.accountData.email,
        })

        if (emailAlreadyUsed) {
          this.errorMessage = this.$t('login.email_registered')
          this.$notification.error(this.$t('login.email_registered'))
          this.nextStepLoading = false

          return
        }

        this.accountData.createWorkspace = createWorkspace
        this.emailAlreadyUsed = emailAlreadyUsed

        this.$v.accountData.$touch()
        if (this.$v.accountData.$error || this.emailAlreadyUsed) {
          if(!this.$v.accountData.required) {
            this.$notification.error(this.$t('password.required'))
          }
          if(!this.$v.accountData.minLength) {
            this.$notification.error(this.$t('password.min_length_error'))
          }
          if(!this.$v.accountData.containsUppercase) {
            this.$notification.error(this.$t('password.uppercase_error'))
          }
          if(!this.$v.accountData.containsSpecialChar) {
            this.$notification.error(this.$t('password.special_char_error'))
          }

          this.nextStepLoading = false

          return
        }

        setTimeout(() => {
          this.$emit('on-next-step')
          this.nextStepLoading = false
        }, 800)
      } catch (e) {
        this.$notification.error(getErrorResponseMessage(e) || this.$t('login.general_error'))
        this.nextStepLoading = false
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.access-data__title {
  color: #424242;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
}

.access-data__registration-note {
  color: #424242 !important;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 17.5px;
  font-weight: 400 !important;
}

:deep(.access-data__registration-note) a {
  color: #424242 !important;
  font-size: 14px;

  &:hover {
    color: var(--v-primary-base) !important;
  }
}

.register-promo-code-input-toggle {
  cursor: pointer;
  font-size: 15px;

  & > span:first-child {
    margin-right: 10px;
    cursor: pointer;
  }
}

.login-method__separator {
  p {
    color: #424242;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #424242;
    line-height: 0.1em;
    letter-spacing: 0;
    margin: 10px 0 10px;
  }

  p span {
    background: #fff;
    padding: 0 10px;
  }
}

.login-link {
  color: #424242;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 17.5px;
}

.bottom-message {
  border-top: 1px solid #e2e2e2;
  font-size: 15px;
}

.anchor {
  color: #5b5f66 !important;
  font-size: 15px;

  .link:hover {
    color: #5f23a0 !important;
  }

  &.link:hover {
    color: #5f23a0 !important;
  }
}
</style>
