<template>
  <v-card width="100%" max-width="30rem" class="mx-auto" elevation="0">
    <v-card-text class="pb-0">
      <div class="access-registration-bar">
        <div
          class="access-registration-bar__circle"
          :class="{
            current: currentStep === 1,
            filled: currentStep === 1,
          }"
        >
          <div class="access-registration-bar__circle--content text-center">1</div>
        </div>

        <div class="access-registration-bar__line">
          <div class="access-registration-bar__line--content"></div>
        </div>

        <div
          class="access-registration-bar__circle"
          :class="{
            current: currentStep === 2,
            filled: currentStep === 2,
          }"
        >
          <div class="access-registration-bar__circle--content text-center">2</div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'RegistrationProgressBar',
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.access-registration-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 8rem;

  &__circle {
    width: 1.8rem;
    height: 1.8rem;
    background: white;
    border: 1px solid #b6c2d7;
    border-radius: 50%;
    z-index: 3;

    display: flex;
    justify-content: center;
    align-items: center;

    &--content {
      color: #b6c2d7;
    }
  }

  &__circle.filled {
    background: #e8edf5;
    border-color: #e8edf5;
    position: relative;
  }

  &__circle.current {
    width: 2.2rem;
    height: 2.2rem;

    .access-registration-bar__circle--content {
      color: #000000 !important;
      font-size: larger;
      font-weight: bold;
    }
  }

  &__line {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &--content {
      height: 1px;
      background: #b6c2d7;
      width: 100%;
      z-index: 1;
    }
  }
}
</style>
